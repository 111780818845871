import * as React from "react";

import Layout from "../components/Layout";
import StepByStepRoll from "../components/StepByStepRoll";

export default function WorkflowProcedureTemplate({ pageContext }) {
  const { workflowTitle } = pageContext;
  return (
    <Layout>
      <div className="full-width-image-container margin-top-0"></div>
      <section className="section">
        <div className="container">
          <div className="content">
            <StepByStepRoll title={workflowTitle} />
          </div>
        </div>
      </section>
    </Layout>
  );
}
